module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-FXC79MS9FB"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"yubiori.band","short_name":"yubiori","description":"横浜のバンドyubioriの公式webサイト","start_url":"/","orientation":"portrait-primary","background_color":"black","theme_color":"black","display":"standalone","dir":"ltr","lang":"ja-jp","icons":[{"src":"icons/app-icon-48x48.png","type":"image/png","sizes":"48x48"},{"src":"icons/app-icon-96x96.png","type":"image/png","sizes":"96x96"},{"src":"icons/app-icon-144x144.png","type":"image/png","sizes":"144x144"},{"src":"icons/app-icon-192x192.png","type":"image/png","sizes":"192x192"},{"src":"icons/app-icon-512x512.png","type":"image/png","sizes":"512x512"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
