exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-send-tsx": () => import("./../../../src/pages/contact/send.tsx" /* webpackChunkName: "component---src-pages-contact-send-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-live-booking-cancel-index-tsx": () => import("./../../../src/pages/live-booking/cancel/index.tsx" /* webpackChunkName: "component---src-pages-live-booking-cancel-index-tsx" */),
  "component---src-pages-live-booking-cancel-send-tsx": () => import("./../../../src/pages/live-booking/cancel/send.tsx" /* webpackChunkName: "component---src-pages-live-booking-cancel-send-tsx" */),
  "component---src-pages-live-booking-edit-index-tsx": () => import("./../../../src/pages/live-booking/edit/index.tsx" /* webpackChunkName: "component---src-pages-live-booking-edit-index-tsx" */),
  "component---src-pages-live-booking-edit-send-tsx": () => import("./../../../src/pages/live-booking/edit/send.tsx" /* webpackChunkName: "component---src-pages-live-booking-edit-send-tsx" */),
  "component---src-pages-live-booking-send-tsx": () => import("./../../../src/pages/live-booking/send.tsx" /* webpackChunkName: "component---src-pages-live-booking-send-tsx" */),
  "component---src-pages-live-booking-tsx": () => import("./../../../src/pages/live-booking.tsx" /* webpackChunkName: "component---src-pages-live-booking-tsx" */),
  "component---src-templates-goods-archive-tsx": () => import("./../../../src/templates/goodsArchive.tsx" /* webpackChunkName: "component---src-templates-goods-archive-tsx" */),
  "component---src-templates-goods-tsx": () => import("./../../../src/templates/goods.tsx" /* webpackChunkName: "component---src-templates-goods-tsx" */),
  "component---src-templates-lives-archive-tsx": () => import("./../../../src/templates/livesArchive.tsx" /* webpackChunkName: "component---src-templates-lives-archive-tsx" */),
  "component---src-templates-lives-tsx": () => import("./../../../src/templates/lives.tsx" /* webpackChunkName: "component---src-templates-lives-tsx" */),
  "component---src-templates-musics-archive-tsx": () => import("./../../../src/templates/musicsArchive.tsx" /* webpackChunkName: "component---src-templates-musics-archive-tsx" */),
  "component---src-templates-musics-tsx": () => import("./../../../src/templates/musics.tsx" /* webpackChunkName: "component---src-templates-musics-tsx" */),
  "component---src-templates-news-archive-tsx": () => import("./../../../src/templates/newsArchive.tsx" /* webpackChunkName: "component---src-templates-news-archive-tsx" */),
  "component---src-templates-news-tsx": () => import("./../../../src/templates/news.tsx" /* webpackChunkName: "component---src-templates-news-tsx" */),
  "component---src-templates-videos-archive-tsx": () => import("./../../../src/templates/videosArchive.tsx" /* webpackChunkName: "component---src-templates-videos-archive-tsx" */)
}

