// sass
import './src/styles/app.scss'
import 'swiper/css/bundle'

// fontawesome
import { config, dom, library } from '@fortawesome/fontawesome-svg-core'
import { faPlayCircle, faEnvelope } from '@fortawesome/free-regular-svg-icons'
import {
    faHeadphones,
    faAngleUp,
    faCheckCircle,
    faExclamationCircle,
    faUserCircle,
    faPencilAlt,
    faUserFriends,
    faGlassCheers,
    faPlay,
    faShoppingCart,
} from '@fortawesome/free-solid-svg-icons'
// 見つからない場合のiconを表示させない
config.showMissingIcons = false
// fontの追加
library.add(
    faPlayCircle,
    faEnvelope,
    faHeadphones,
    faAngleUp,
    faExclamationCircle,
    faCheckCircle,
    faUserCircle,
    faPencilAlt,
    faUserFriends,
    faGlassCheers,
    faPlay,
    faShoppingCart
)
// svgタグに変換
dom.watch()
